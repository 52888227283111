.references {
    display: flex;
    flex: auto;
}

.references-left {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: 3rem;
    flex: 1;
    margin-top: 3rem;
    margin-right: 3rem;
}

.references-right {
    flex: 1;
    background-image: url('../assets/usine.webp');
    background-size: auto 100%;
    background-position: 30%;
}

.logos {
    display: flex;
    align-self: center;
    width: 70%;
    align-items: center;
}

.references-left > .logos > img {
    width: 8rem;
    margin-right: 5rem;
    animation: slide-in-logos 2s;
}

.industrie {
    animation: slide-in 1s;
}

.type-entreprise {
    animation: slide-in 1.3s;
}

.investment-funds {
    animation: slide-in 1.6s;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        opacity: 0.25;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-in-logos {
    from {
        transform: translateX(-600%);
        opacity: 0.25;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .references-right {
        display: none;
    }

    .references-left {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .logos {
        flex-direction: column;
    }

    .references-left > .logos > img {
        width: 15rem;
        margin-right: auto;
        animation: slide-in-logos 2s;
    }
}
