.navbar{
}

.logo {
    width: auto;
    height: 4rem;
}

.logo-small {
    width: auto;
    height: 4rem;
}

.changeLang{
    margin-right: 0rem;
    border: none;
    color: rgba(0,0,0,.55);
    transition: none;
    background-color: transparent;
}

.navbar .nav-link{
    color: rgba(0,0,0,.55);
}

@media only screen and (max-width: 600px) {
    .navbar-brand{
        display: none;
    }

    .logo-small {
        display: none;
        width: auto;
        height: 4rem;
    }

    .navbar-toggler{
        order: 2;
    }

    .changeLang{
        order: 1;

    }
    
}