.contact {
    flex: auto;
    background-image: url('../assets/boussole.webp');
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.contact-content {
    animation: pop-up 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    color: white;
}

.email {
    color: white;
}

.contact > contact-content > a {
    color: white;
}

@keyframes pop-up {
    from {
        transform: scale(0);
        opacity: 0.25;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}
