.not-found {
    flex: auto;
    display: flex;
    flex-direction: column;
    background-image: url('../assets/road.webp');
    background-size: 100% auto;
    background-position: 50% 60%;
    color: white;
    background-repeat: no-repeat;
}

.not-found > h1 {
    margin-left: 1rem;
    margin-top: 10vh;
}

.not-found > h2 {
    margin-left: 1rem;
}


.back-to-home {
    margin-top: 10vh;
    align-self: center;
    background-color: transparent;
    border-color: white;
}

@media only screen and (max-width: 600px) {
    .not-found {
        background-position: 0% 0%;
    }

    .not-found > h1 {
        margin-top: 0px;
        color: black;
    }

    .not-found > h2 {
        margin-top: 0px;
        color: black;
    }
}
