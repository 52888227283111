.about {
    flex: auto;
    display: flex;
}

.about-left {
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-left: 3rem;
    margin-right: 3rem;
    flex: 2;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.about-right {
    flex: 1;
    background-image: url('../assets/engrenages.webp');
    background-size: auto 100%;
    background-position: 40%;
}

.raison-d-etre {
    animation: slide-in 1s;
}

.objectif {
    animation: slide-in 1.4s;
}

.equipe {
    animation: slide-in 1.8s;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        opacity: 0.25;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .about-right {
        display: none;
    }

    .about-left {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
