.footer {
    background-color: #fefee2;
    display: flex;
    justify-content: center;
    padding-top: 0px;
}

.side-footer {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    justify-content: center;
    font-size: 24px;
}

.footer > ul > * {
    font-size: 20px;
    margin-top: 0px;
}

.footer > small {
    font-size: 5px;
}

@media only screen and (max-width: 600px) {
    .footer-nav {
        display: none;
    }

    .footer {
        margin-top: 1rem;
        flex-direction: column-reverse;
    }
}
