.home {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-left: 0rem;
    margin-right: 0rem;
    background-image: url('../assets/puzzle_ville.webp');
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.discover-container {
    width: 100;
    display: flex;
    justify-content: center;
}

.discover {
    align-self: center;
    background-color: transparent;
}

.translated-title {
    animation: Title-translation 1.5s;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 3rem;
}

.btn {
    background-color: #253676;
}

.btn:hover {
    background-color: #5161A9;
    border: none;
}


@keyframes Title-translation {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}

@media only screen and (max-width: 600px) {
    .discover {
        margin-top: 3rem;
    }
}
