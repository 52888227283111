.services {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 0rem;
    margin-right: 0rem;
}

.services-title-container {
    position: relative;
    text-align: center;
}

.puzzle {
    width: 100%;
}

.services-title {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.services-title-container > h1 {
    margin-left: 5rem;
}

.first-part {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: 3rem;
}

.second-part {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    margin-left: 3rem;
    margin-right: 3rem;
}

.service-1 {
    animation: slide-in 1s 0s;
}

.service-2 {
    animation: slide-in 1.3s;
}

.service-3 {
    animation: slide-in 1.6s;
}

.service-4 {
    animation: slide-in 2.0s;
}

.service-5 {
    animation: slide-in 2.2s;
}

.service-6 {
    animation: slide-in 2.5s;
}

.service-2-1 {
    animation: slide-in 1.0s;
}

.service-2-2 {
    animation: slide-in 1.3s;
}

.service-2-3 {
    animation: slide-in 1.6s;
}

.service-2-4 {
    animation: slide-in 1.9s;
}

.service-2-5 {
    animation: slide-in 2.2s;
}

.service-2-6 {
    animation: slide-in 2.5s;
}

.service-2-7 {
    animation: slide-in 2.8s;
}

.service-2-8 {
    animation: slide-in 3.1s;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        opacity: 0.25;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .puzzle {
        hidden: false;
    }

    .services-title-container > h1 {
        margin-left: 1rem;
    }

    .first-part {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .second-part {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        margin-left: 1rem;
        margin-right: 1rem;
        align-items: flex-start;
        text-align: start;
    }
}
